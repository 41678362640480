import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container main" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "form d-block" }
const _hoisted_6 = { class: "d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "header-image" }, null, -1)),
          _createVNode(_component_Card, { heading: "Registreren" }, {
            subtitle: _withCtx(() => [
              _cache[8] || (_cache[8] = _createTextVNode(" Al een account? ")),
              _createVNode(_component_router_link, {
                class: "inline-router-link",
                to: { name: 'Login' }
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("span", null, " Inloggen.", -1)
                ])),
                _: 1
              })
            ]),
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.firstname.$model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.vv.firstname.$model) = $event)),
                  "has-errors": $setup.vv.firstname.$error || $setup.serverValidation.firstname,
                  error: $setup.serverValidation.firstname,
                  "form-required": true,
                  label: "Naam",
                  type: "text"
                }, null, 8, ["modelValue", "has-errors", "error"]),
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.middlename.$model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.vv.middlename.$model) = $event)),
                  "has-errors": $setup.vv.middlename.$error,
                  label: "Tussenvoegsels",
                  type: "text"
                }, null, 8, ["modelValue", "has-errors"]),
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.lastname.$model,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.vv.lastname.$model) = $event)),
                  "has-errors": $setup.vv.lastname.$error || $setup.serverValidation.lastname,
                  error: $setup.serverValidation.lastname,
                  "form-required": true,
                  label: "Achternaam",
                  type: "text"
                }, null, 8, ["modelValue", "has-errors", "error"]),
                _createVNode(_component_DateInput, {
                  date: $setup.user.dob,
                  "has-errors": $setup.vv.dob.$error  || $setup.serverValidation.dob,
                  error: $setup.serverValidation.dob,
                  "form-required": true,
                  label: "Geboortedatum"
                }, null, 8, ["date", "has-errors", "error"]),
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.customer_no.$model,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.vv.customer_no.$model) = $event)),
                  "has-errors": $setup.vv.customer_no.$error  || $setup.serverValidation.customer_no,
                  error: $setup.serverValidation.customer_no,
                  "form-required": true,
                  label: "Debiteurnummer",
                  type: "text"
                }, null, 8, ["modelValue", "has-errors", "error"]),
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.email.$model,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.vv.email.$model) = $event)),
                  "has-errors": $setup.vv.email.$error || $setup.serverValidation.email,
                  error: $setup.serverValidation.email,
                  "form-required": true,
                  label: "E-mailadres",
                  type: "text"
                }, null, 8, ["modelValue", "has-errors", "error"]),
                _createVNode(_component_TextInput, {
                  modelValue: $setup.vv.password.$model,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.vv.password.$model) = $event)),
                  "has-errors": $setup.vv.password.$error || $setup.serverValidation.password,
                  error: $setup.serverValidation.password,
                  "form-required": true,
                  label: "Wachtwoord",
                  type: $setup.passType,
                  onIconRightClick: $setup.showHidePassword
                }, {
                  "icon-right": _withCtx(() => [
                    _createVNode(_component_EyeIcon, { class: "icon" })
                  ]),
                  _: 1
                }, 8, ["modelValue", "has-errors", "error", "type", "onIconRightClick"]),
                _createVNode(_component_Checkbox, {
                  modelValue: $setup.vv.accept.$model,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.vv.accept.$model) = $event)),
                  "has-errors": $setup.vv.accept.$error,
                  label: "Ik ga akkoord met de <a class=\"red text-decoration-none fw-bold\" href=\"https://www.kwastwijnkopers.nl/contact/algemene-voorwaarden/\" target=\"_blank\">\n                                                algemene voorwaarden\n                                            </a>"
                }, null, 8, ["modelValue", "has-errors"]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    disabled: !$setup.vv.accept.$model,
                    type: "button",
                    design: "primary",
                    text: "registreren",
                    onButtonClick: $setup.validate
                  }, null, 8, ["disabled", "onButtonClick"])
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}