
import { useVuelidate } from '@vuelidate/core';
import {
    email, required,
} from '@vuelidate/validators';
import { classToPlain } from 'class-transformer';
import { ref } from 'vue';
import { useStore } from 'vuex';
import EyeIcon from '@/assets/icons/eye-outline.svg';
import router from '@/router';
import Button from '@/shared/components/Button.vue';
import Card from '@/shared/components/Card.vue';
import Checkbox from '@/shared/components/form/Checkbox.vue';
import DateInput from '@/shared/components/form/DateInput.vue';
import TextInput from '@/shared/components/form/TextInput.vue';
import { User } from '@/shared/models/User';

export default {
    components: {
        Checkbox,
        Card,
        TextInput,
        Button,
        DateInput,
        EyeIcon,
    },
    setup() {
        const user = ref(new User());
        const store = useStore();
        const mustBeOn = (value: boolean) => value;
        const alphaSpace = (value: string) => /^[a-zA-Z ]*$/.test(value);

        const rules = {
            firstname: { required },
            middlename: { alphaSpace },
            lastname: { required },
            customer_no: { required },
            email: { required, email },
            password: { required },
            gender: { required },
            dob: {
                required,
                date: { required },
                month: { required },
                year: { required },
            },
            accept: {
                required,
                mustBeOn,
            },
        };

        const serverValidation: any = ref({});

        const vv = useVuelidate(rules, user);

        function clearServerErrors() {
            serverValidation.value = {};
        }

        const validate = () => {
            vv.value.$touch();
            clearServerErrors();
            const newUser = classToPlain(user.value);
            if (vv.value.$invalid) {
                return;
            }
            store.dispatch('REGISTER', newUser)
                .then(() => {
                    router.push({ name: 'Login' });
                }).catch((err) => {
                    if (err && err.response && err.response.status === 400) {
                        const errors = err.response.data.data.attributes;
                        for (const [key, value] of Object.entries(errors)) {
                            const attribute = key.split('.')[2];
                            serverValidation.value[attribute] = value;
                        }
                    }
                });
        };

        const passType = ref('password');
        function showHidePassword() {
            if (passType.value === 'password') {
                passType.value = 'text';
                return;
            }
            passType.value = 'password';
        }

        return {
            user,
            validate,
            vv,
            passType,
            showHidePassword,
            serverValidation,
        };
    },
};
