<template>
    <div class="input-container">
        <label>
            {{ label }}
            <span v-if="formRequired">
                *
            </span>
        </label>
        <div class="input-wrapper d-flex flex-row justify-content-between">
            <input
                v-model="dateObject.date"
                :class="{
                    'has-errors' : hasErrors,
                }"
                type="number"
                @input="setDate($event.target.value)"
            />
            <input
                v-model="dateObject.month"
                :class="{
                    'has-errors' : hasErrors,
                }"
                type="number"
                @input="setDate($event.target.value)"
            />
            <input
                v-model="dateObject.year"
                :class="{
                    'has-errors' : hasErrors,
                }"
                type="number"
                @input="setDate($event.target.value)"
            />
        </div>

        <p v-if="hasErrors && error" class="error">
            {{ error }}
        </p>
    </div>
</template>

<script type="ts">
import moment from 'moment';
import { ref } from 'vue';

export default {
    props: {
        hasErrors: {
            default: false,
        },
        error: {
            type: String,
            required: false,
        },
        label: {
            type: String,
        },
        formRequired: {
            type: Boolean,
            default: false,
        },
        date: {
            type: Object,
            date: {
                type: Number,
            },
            month: {
                type: Number,
            },
            year: {
                type: Number,
            },
        },
    },
    setup(props, context) {
        const dateObject = ref(props.date);
        function setDate(value) {
            if (value) {
                const now = moment();
                let date = moment().set({
                    date: dateObject.value.date,
                    month: dateObject.value.month - 1,
                    year: dateObject.value.year,
                });

                if (date.isAfter(now)) {
                    date = now;
                }

                dateObject.value.month = date.month() + 1;
                dateObject.value.date = date.date();
                dateObject.value.year = date.year();
            }
        }

        function emitBlur() {
            context.emit('blur');
        }

        return {
            dateObject,
            setDate,
            emitBlur,
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";

.input-container {
    display: block;

    label {
        text-align: left;
        width: 100%;
        height: 26px;
        margin: 8px 0;
        font-family: 'DINPro';
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #000000;
    }

    .input-wrapper {
        position: relative;

        input:nth-of-type(1) {
            margin-right: 10px;
        }

        input:nth-of-type(2) {
            margin: 0 10px;
        }

        input:nth-of-type(3) {
            margin-left: 10px;
        }

        input {
            width: 100%;
            margin-bottom: 10px;
            height: 40px;
            background-color: #f2f2f2;
            border: none;
            padding-left: .75rem;
            padding-right: .75rem;
            &:focus {
                outline: none;
            }

            &.has-errors {
                border: 3px solid $kwast-red;
                color: $kwast-red;
                background: $kwast-lightred;
                margin-bottom: 10px;
            }

            &.has-icon {
                padding-right: 3rem;
            }
        }
    }

    .error {
        text-align: left;
        color: $kwast-red;
        font-size: 1rem;
    }
}
</style>
